import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import {
  Twitter,
  Reddit,
  Instagram,
  Telegram,
  Discord
} from '@icons-pack/react-simple-icons';

const socials = [
  {
    name: 'Twitter',
    href: 'https://twitter.com/OfficialPumpETH',
    icon: Twitter,
  },
  // {
  //   name: 'Reddit',
  //   href: 'https://www.reddit.com/r/TheRealPumpETH/',
  //   icon: Reddit,
  // },
  {
    name: 'Telegram',
    href: 'https://t.me/OfficialPumpETH',
    icon: Telegram,
  },
]



export default function Cta() {
  return (
    <div>
      <div className="relative py-16 sm:py-24 lg:py-32 bg-white z-20" id="cta">
        <div className="container max-w-5xl">
          <div className="bg-gradient-to-tr from-blue-400 to-blue-600 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4 items-center">
            <div className="pt-10 pb-12 px-6 sm:pt-14 sm:px-14 lg:py-14 lg:pr-0 xl:py-16 xl:px-16">
              <div className="lg:self-center">
                <h2 className="text-3xl font-bold text-white sm:text-4xl">
                  <span className="block">Join Our Community</span>
                </h2>
                <p className="mt-4 text-base leading-6 text-blue-100 lg:text-lg">
                  Join the most exciting & rewarding token on the Ethereum Chain!
                </p>
                <a href="https://app.uniswap.org/#/swap?outputCurrency=0x9eab8e97c6326901a322803b27cae25e9c61b05f&use=V2" target="_blank" className="mt-8 inline-flex items-center flex-shrink-0 text-blue-600 bg-white transform hover:bg-blue-50  px-4 py-2.5 lg:px-8 lg:py-4 text-sm md:text-base font-medium rounded-full focus:outline-none leading-tight space-x-1 lg:space-x-1.5">
                    <span>Buy PumpETH</span>
                  </a>
                <div className="flex space-x-5 mt-10">
                  {socials.map((item) => (
                    <a key={item.name} href={item.href} className="text-white hover:text-white">
                      <span className="sr-only">{item.name}</span>
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </a>
                  ))}
                </div>
              </div>
            </div>
            <div className="-mt-12 lg:mt-0 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
              <StaticImage
                src="../images/eth-hands.png"
                className="transform rounded-md object-cover translate-x-12 sm:translate-x-0 object-center"
                width={1000}
                alt=""
                objectFit="contain"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}